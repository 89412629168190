import styled from "styled-components";
import { theme } from "src/styles/global";

export const Title = styled.h1`
  font-family: "Dinish", sans-serif;
  font-size: 80px;
  font-weight: 700;
  color: ${({ color = theme.colors.magnolia }) => color};
  margin: 0;
  line-height: 0.9;
  max-width: 800px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  text-transform: uppercase;
  opacity: inherit;

  @media (${theme.screenSize.desktop}) {
    font-size: 60px;
  }

  @media (${theme.screenSize.laptop}) {
    font-size: 50px;
  }

  @media (${theme.screenSize.tablet}) {
    font-size: 50px;
  }

  @media (${theme.screenSize.mobile}) {
    font-size: 40px;
  }
`;

export const SubTitle = styled.span`
  font-family: ${({ family = "Montserrat" }) => `${family}, sans-serif`};
  color: ${({ color = theme.colors.graySuit }) => color};
  font-style: normal;
  font-weight: 400;
  font-size: ${({ size = 16 }) => size + "px"};
  line-height: 150%;
`;

export const Gap = styled.section`
  height: ${({ height = 17 }) => height}px;
`;
