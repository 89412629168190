import React from "react";
import { Title as Label, Gap, SubTitle } from "./styled";
import { theme } from "src/styles/global";

export const Title = ({
  title,
  subTitle,
  gap = 10,
  color = theme.colors.magnolia,
  subColor = theme.colors.graySuit,
}) => {
  return (
    <div>
      <Label data-title color={color}>
        {title}
      </Label>
      <Gap height={gap} />
      <SubTitle data-subtitle color={subColor}>
        {subTitle}
      </SubTitle>
    </div>
  );
};
