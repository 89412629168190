import React from "react";
import { Helmet } from "react-helmet";

export function HelmetComponent({
  title = "GreyApes | The studio that will boost your brand through design.",
  description = "A team of experienced UI/UX, Web & Mobile App and Graphic designers. We make every user interaction with your brand flawless.",
  keywords = "web design studio,web design studio Cyprus, web design agency,  top web design agency, landing page design, UI/UX designer, brand identity, social media cover, social media, web designers near me ui/ux app ui/ux artistui/ux agencymaking a landing page designlanding page design servicesui/ux analystbest ui/ux designhire a ui/ux designerbest ui/uxcorporate identity designcorporate identity and brandingwordpress website designerwebsite design businesses",
  robots = "index, follow",
  site_name = "monkey",
  url = "",
  type = "book",
  image = "",
}) {
  return (
    <Helmet>
      <title>{title}</title>

      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />
      <meta name="robots" content={robots} />

      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={site_name} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content={type} />
      <meta property="og:image" content={image} />
    </Helmet>
  );
}
