import styled from "styled-components";
import { Link } from "gatsby";

import { globalMargin, globalPadding, theme } from "./global";

export const Flex = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  flex-wrap: ${({ wrap }) => wrap};
  flex: ${({ flex }) => flex};
  gap: ${({ gap }) => gap};

  ${globalMargin};
  ${globalPadding};
`;

export const Button = styled.button`
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: ${({ size = 14 }) => size + "px"};
  color: ${({ color = theme.colors.magnolia }) => color};
  border-radius: 40px;
  border: 1px solid ${({ color = theme.colors.magnolia }) => color};
  background: none;
  padding: 1.25em 42px;
  cursor: pointer;

  ${globalMargin};
  ${globalPadding};

  &:hover {
    color: ${({ hoverColor = theme.colors.woodsmoke }) => hoverColor};
    background: ${({ color = theme.colors.magnolia }) => color};
  }
`;

export const H1 = styled.h1`
  color: ${({ color = theme.colors.white }) => color};
  font-family: ${({ family = "Dinish" }) => `${family}, sans-serif`};
  font-weight: ${({ weight = 700 }) => weight};
  text-transform: ${({ transform }) => transform};
  font-size: ${({ size = 90 }) => size + "px"};
  line-height: ${({ lineHeight = 1 }) => lineHeight};
  text-align: ${({ align }) => align};
  margin: 0;
  ${globalMargin};

  @media (${theme.device.laptop}) {
    font-size: 72px;
  }

  @media (${theme.device.tablet}) {
    font-size: 40px;
  }
`;

export const H2 = styled.h2`
  color: ${({ color = theme.colors.white }) => color};
  font-family: ${({ family = "Dinish" }) => `${family}, sans-serif`};
  font-weight: ${({ weight = 400 }) => weight};
  text-transform: ${({ transform }) => transform};
  font-size: ${({ size = 60 }) => size + "px"};
  line-height: ${({ lineHeight = 1 }) => lineHeight};
  text-align: ${({ align }) => align};
  margin: 0;
  margin-bottom: 40px;
  ${globalMargin};

  @media (${theme.device.tablet}) {
    font-size: 40px;
  }

  @media (${theme.device.mobile}) {
    margin-bottom: 0;
  }
`;

export const Text = styled.p`
  color: ${({ color = theme.colors.scarpaFlow }) => color};
  font-family: ${({ family = "Montserrat" }) => `${family}, sans-serif`};
  font-weight: ${({ weight = 400 }) => weight};
  font-size: ${({ size = 14 }) => size + "px"};
  line-height: ${({ lineHeight = 1.5 }) => lineHeight};
  text-align: ${({ align }) => align};
  ${globalMargin};
`;

export const SCLink = styled(Link)`
  color: ${({ color = theme.colors.white }) => color};
  font-family: ${({ family = "Montserrat" }) => `${family}, sans-serif`};
  font-weight: ${({ weight = 600 }) => weight};
  font-size: ${({ size = 14 }) => size + "px"};
  line-height: ${({ lineHeight = 1.5 }) => lineHeight};
`;

export const Hr = styled.hr`
  border-color: #242229;
  width: 100%;
`;
