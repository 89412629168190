import { css } from "styled-components";

export const theme = {
  colors: {
    white: "#FBFAFF",
    red: "#FF3115",
    black: "#000",
    graySuit: "#C4BFCE",
    magnolia: "#FBFAFF",
    woodsmoke: "#0A0B0D",
    scarpaFlow: "#54525C",
    charade: "#262635",
    santasGray: "#A1A2AC",
    whisper: "#F0EEF4",
    mulledWine: "#414067",
  },
  device: {
    desktop: "max-width: 1281px",
    laptop: "max-width: 1024px",
    tablet: "max-width: 768px",
    mobile: "max-width: 640px",
  },
  screenSize: {
    fullScreen: "max-width: 1920px",
    desktop: "max-width: 1600px",
    laptop: "max-width: 1200px",
    tablet: "max-width: 768px",
    mobile: "max-width: 640px",
  },
  screenSizeMin: {
    fullScreen: "min-width: 1920px",
    desktop: "min-width: 1600px",
    laptop: "min-width: 1200px",
    tablet: "min-width: 768px",
    mobile: "min-width: 640px",
  },
  links: {
    instagram: "https://www.instagram.com/greyapes_studio/",
    facebook: "https://www.facebook.com/greyapes.studio/",
    telegram: "https://t.me/DmitryRevchuk",
    behance: "https://www.behance.net/GreyApes",
    dribbble: "https://dribbble.com/search/greyapes",
    shareLink: "https://test-static-monkey.herokuapp.com",
  },
};

export const globalMargin = css`
  margin-bottom: ${({ mb, my }) => (mb || my) && (mb || my) + "rem"};
  margin-top: ${({ mt, my }) => (mt || my) && (mt || my) + "rem"};
  margin-left: ${({ ml, mx }) => (ml || mx) && (ml || mx) + "rem"};
  margin-right: ${({ mr, mx }) => (mr || mx) && (mr || mx) + "rem"};
`;

export const globalPadding = css`
  padding-bottom: ${({ pb, py }) => (pb || py) && (pb || py) + "rem"};
  padding-top: ${({ pt, py }) => (pt || py) && (pt || py) + "rem"};
  padding-left: ${({ pl, px }) => (pl || px) && (pl || px) + "rem"};
  padding-right: ${({ pr, px }) => (pr || px) && (pr || px) + "rem"};
`;

export const globalWrapperSize = css`
  box-sizing: border-box;
  width: 100vw;
  margin: 0 auto;
  max-width: 1560px;
  padding-left: 3vw;
  padding-right: 3vw;

  @media (${theme.device.desktop}) {
    padding-left: 52px;
    padding-right: 52px;
  }

  @media (${theme.device.mobile}) {
    padding-left: 25px;
    padding-right: 25px;
  }
`;
