import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";
import DinishRegular from "../assets/fonts/Dinish-Regular.ttf";
import DinishBold from "../assets/fonts/Dinish-Bold.ttf";
import MontserratRegular from "../assets/fonts/Montserrat-Regular.ttf";
import MontserratMedium from "../assets/fonts/Montserrat-Medium.ttf";
import MontserratSemiBold from "../assets/fonts/Montserrat-SemiBold.ttf";
import MontserratBold from "../assets/fonts/Montserrat-Bold.ttf";
import { theme } from "./global";
import './main.scss';

export const GlobalStyle = createGlobalStyle`
  ${normalize};
  
  .content-wrapper::-webkit-scrollbar {
    background-color: transparent;    
    width: 0;
  }

  * {
    box-sizing: border-box;
  }
  
  a {
    text-decoration: none;
  }


  body {
    background: ${theme.colors.woodsmoke};    
    height: 100vh;
    max-width: 100vw;  
    overflow-y: auto;
    overflow-x: hidden;
  }
  


  html {
    scroll-behavior: smooth;
  }

  @font-face {
    font-family: 'Dinish';
    src: url(${DinishRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Dinish';
    src: url(${DinishBold}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url(${MontserratRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url(${MontserratMedium}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url(${MontserratSemiBold}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url(${MontserratBold}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  .scroll-thumb {
    background-color: rgba(70, 71, 111,0.8);
    border-radius: 8px;
    height: 100px;
  }

  @media(min-width: 1024px) {
    /* total width */
    body::-webkit-scrollbar {
        background-color: transparent;
        /* width: 8px; */
        width: 0;
    }

    /* background of the scrollbar except button or resizer */
    body::-webkit-scrollbar-track {
        background-color: transparent;
    }

    /* scrollbar itself */
    body::-webkit-scrollbar-thumb {
        background-color: rgba(70, 71, 111,0.8);
        border-radius: 8px;
        border: 2px solid black;
    }

    /* set button(top and bottom of the scrollbar) */
    body::-webkit-scrollbar-button {
        display:none;
    }
  }

  @keyframes zoomAnimation {
    0% {
      transform: scale(1);
    }

    10% { 
      transform: scale(1.15);
      
    }

    30% { 
      transform: scale(1.15);
      
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes zoomAnimationRotate {
    0% {
      transform: rotate(20deg) scale(1);
    }

    100% { 
      transform: rotate(0deg) scale(1.15);
    }
  }
`;
